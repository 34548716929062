var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth sign-up" }, [
    _c("div", { staticClass: "auth-wrapper py-40" }, [
      _c("div", { staticClass: "card mt-md-90" }, [
        _c("div", { staticClass: "card__content pt-60 pb-52 px-56" }, [
          _c("img", {
            staticClass: "d-block w-100 mw170 mx-auto mb-60",
            attrs: { src: require("@/assets/svg/logo_full.svg"), alt: "" }
          }),
          _c("div", { staticClass: "signupForm" }, [
            _c("div", { staticClass: "text-center mb-40" }, [
              _c("p", { staticClass: "fs18 fw500" }, [
                _vm._v(
                  " Sign up for Domestic shipping is currently unavailable"
                )
              ]),
              _c(
                "p",
                { staticClass: "fs18 fw500" },
                [
                  _c(
                    "b-link",
                    {
                      staticClass: "fs18",
                      attrs: {
                        target: "_blank",
                        href: "https://app.shypmax.com/sign-up"
                      }
                    },
                    [_vm._v("Click here")]
                  ),
                  _vm._v(" to sign up for international shipping ")
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex flex-column align-items-center mb-20 mb-md-0"
            },
            [
              _c(
                "span",
                { staticClass: "fs12 lh20 mb-16 text-gray-800" },
                [
                  _vm._v(" Already registered? "),
                  _c("b-link", { attrs: { href: "/login" } }, [
                    _vm._v(" Log In ")
                  ])
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "fs12 lh20 text-gray-800" },
                [
                  _vm._v(" Contact us: "),
                  _c(
                    "b-link",
                    { attrs: { href: "mailto:info@shyplite.com" } },
                    [_vm._v("info@shyplite.com")]
                  )
                ],
                1
              )
            ]
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "bottom-bar pt-10 d-flex justify-content-between mx-40"
        },
        [
          _c(
            "span",
            { staticClass: "fs12 lh20 text-gray-400 mb-16 mb-md-32" },
            [_vm._v(" © 2021 Shyplite — All Rights Reserved. ")]
          ),
          _c("ul", { staticClass: "lsnone p-0 mb-16 mb-md-32" }, [
            _c(
              "li",
              [
                _c(
                  "b-link",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/tou"
                    }
                  },
                  [_vm._v("Terms")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/privacy"
                    }
                  },
                  [_vm._v("Privacy")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/contact/"
                    }
                  },
                  [_vm._v("Contact Us")]
                )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }