<template>
	<div class="auth sign-up">
		<div class="">
			<div class="m-auto" style="min-height: calc(100vh - 65px);">
				<div class="card__content pt-60 pb-52 px-56">
					<img
					src="@/assets/svg/logo_full.svg"
					alt=""
					class="d-block w-100 mw170 mx-auto mb-60"
					/>

					<div class="signupForm" v-if="!user.preference">
						<b-form @submit.prevent="SignUpFormSubmit" class="form mw644">
							<b-form-group label="What describes your requirement?" class="text-gray-800 fs18">
								<div class="inputGroup">
									<input type="radio" id="option1" v-model="preference" value="One-time Shipping">
									<label for="option1">I want to ship a Personal package (Non-Commercial)</label>
								</div>
								<div class="inputGroup">
									<input type="radio" id="option2" v-model="preference" value="Regular Shipping">
									<label for="option2">I have a business which requires Regular Shipping services (Commercial)</label>
								</div>
								<div class="inputGroup">
									<input type="radio" id="option3" v-model="preference" value="Shipment Tracking">
									<label for="option3">I want to check the Tracking status of my order</label>
								</div>
							</b-form-group>              

							<b-button
							variant="primary"
							size="lg"
							:disabled="!preference || submitting"
							class="w-100 mb-20 mb-md-40"
							type="submit"
							>
							Next <b-spinner v-if="submitting" class="ml-10"></b-spinner>
						</b-button>
					</b-form>
				</div>

				<transition name="slide-left">
					<b-form v-if="user.preference=='Regular Shipping'" @submit.prevent="submitLastRegular" class="form form-width">
						<h4 class="mb-40">Tell us about your business</h4>

						<b-form-group label="What is your monthly order volume?" class="text-gray-800 mb-30 fs18">
							<div class="row">
								<div v-for="item in monthlyShipment" class="col-md-4">
									<div class="inputGroup">
										<input type="radio" v-model="form.monthlyShipment" :id="item" :value="item">
										<label :for="item">{{item}}</label>

									</div>
								</div>
							</div>
						</b-form-group>

						<b-form-group label="Please select all of your sales channels." class="text-gray-800 mb-30 fs18">
							<div class="row">
								<div v-for="channel in channels" class="col-md-4">
									<div class="inputGroup">
										<input type="checkbox" v-model="form.salesChannel" :id="channel.name" :value="channel.name">
										<label :for="channel.name">{{channel.name}}</label>

									</div>
								</div>
							</div>
						</b-form-group>

						<b-form-group label="Do you have GST number?" class="text-gray-800 mb-30 fs18">
							<div class="row">
								<div v-for="item in isGST" class="col-md-4">
									<div class="inputGroup">
										<input type="radio" v-model="form.isGST" :id="'gst_'+item" :value="item">
										<label :for="'gst_'+item">{{item}}</label>

									</div>
								</div>
							</div>
						</b-form-group>

						<b-form-group label="Do you ship internationally?" class="text-gray-800 mb-30 fs18">
							<div class="row">
								<div v-for="item in shipInternational" class="col-md-4">
									<div class="inputGroup">
										<input type="radio" v-model="form.shipInternational" :id="item" :value="item">
										<label :for="item">{{item}}</label>

									</div>
								</div>
							</div>
						</b-form-group>

						<b-button
						variant="primary"
						size="lg"
						:disabled="submitting"
						class="mb-20 mb-md-40"
						type="submit">
						Submit <b-spinner v-if="submitting" class="ml-10"></b-spinner></b-button>
					</b-form>
				</transition>

				<!-- <transition name="slide-left">
					<div v-if="user.preference=='One-time Shipping'" class="mb300">
						<h4 class="text-center text-red">We don't provide one-time shipping shipping services.</h4>
					</div>
				</transition> -->

				<transition name="slide-left">
					<div v-if="user.preference=='Shipment Tracking'" class="mb300 text-center">
						<h4>Redirecting you to order tracking page... </h4>
						<h4 class="mt-10">{{countdown}}</h4>
					</div>
				</transition>

				<div class="d-flex flex-column align-items-center mb-20 mb-md-0">
            <!-- <span class="fs12 lh20 mb-16 text-gray-800">
              Already registered?
              <b-link to="/login">
                Log In
              </b-link>
          </span> -->
          <!-- <span class="fs12 lh20 text-gray-800">
          	Contact us:
          	<b-link href="mailto:info@shyplite.com">info@shyplite.com</b-link>
          </span> -->
      </div>
  </div>
</div>
<div class="bottom-bar pt-10 d-flex justify-content-between mx-40">
	<span class="fs12 lh20 text-gray-400 mb-16 mb-md-32">
		© 2021 Shyplite — All Rights Reserved.
	</span>
	<ul class="lsnone p-0 mb-16 mb-md-32">
		<li>
			<b-link target="_blank" href="https://shyplite.com/tou">Terms</b-link>
			<b-link class="ml-20" target="_blank" href="https://shyplite.com/privacy">Privacy</b-link>
			<b-link class="ml-20" target="_blank" href="/support">Contact Us</b-link>
		</li>
	</ul>
</div>
</div>
</div>
</template>

<script>
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter
import Multiselect from "vue-multiselect";


export default {
	name: "SignUpDetail",
	title: "Shyplite - SignUpDetail",
	data() {
		return {
			showQuestions:false,
			submitting: false,
			form:{
				salesChannel: []
			},
			preference:'',
			countdown: 0,
			monthlyShipment: ["Less than 100","100 - 500","500 - 1000","1000 - 3000","3000 and above"],
			isGST: ['Yes', 'No', 'Under Process'],
			shipInternational: ['Yes', 'No', 'Planning To'],
			channels: [ 
			{ "id": "1", "name": "Online Store / D2C / Own Website" }, 
			{ "id": "2", "name": "Market Place" }, 
			{ "id": "3", "name": "Social Media" }, 
			{ "id": "4", "name": "B2B / Bulk Supply" }, 
			{ "id": "5", "name": "Franchise" },
			{ "id": "5", "name": "Offline / Retail" }
			],
		};
	},
	components:{
		Multiselect
	}, 
	computed: {
		user() {
			return this.$store.getters.user;
		}
	},
	mounted() {

	},
	created() {
		if (this.user.questionnaire) {
			this.$router.push('/dashboard')
		}
		this.user.preference = false;
	},
	methods: {
		maxten(e){
			return String(e).substring(0, 10);
		},
		async getOtherInfo() {

	      	// GRAPH DATA...
	      	const now = Math.floor(Date.now()/1000)
	      	const date = new Date();
	      	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	      	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	      	const chart = await this.axios.get(`/v3/dashboard/graphround?from=${firstDay.getTime()}&to=${lastDay.getTime()}`)
	      	this.$store.commit('setUser', {chart_timestamp: now + 3*3600})
	      	this.$store.commit('setCharts', chart.data)

	      	// NOTIFICATIONS DATA...
	      	const notice = await this.axios.get('/notification')
	      	if(notice.data.success) {
	      		this.$store.commit('setNotifications', notice.data.notifications)
	      	}


	      	// GET STATICS VARIABLES...
	      	const statics = await this.axios.get('/statics')
	      	this.$store.commit('setModes', statics.data.modes.filter(item => item.name != ''))
	      	this.$store.commit('setCarriers', statics.data.carriers.filter(item => item.name != ''))
	      	this.$store.commit('setChannels', statics.data.channels.filter(item => item.name != ''))
	      	this.$store.commit('setAllmodes', {'mps':statics.data.mps, 'b2b':statics.data.b2b, 'intl':statics.data.intl, 'domestic':statics.data.domestic})


	        // GET WAREHOUSE LIST...
	        let warehouses = await this.axios.get('/warehouse/list')
	        this.$store.commit('setWarehouses', warehouses.data)
	    },

	    async submitLastRegular() {
	    	this.form.id = this.user.id
	    	try {
	    		this.submitting = true
	    		const res = await this.axios.post('/registerQuestionnaire', this.form)
	    		if(res.data.success) {
	    			this.$router.push('/kyc-info')
	    			this.$store.commit('setUser', {questionnaire: true})
	    		}
	    		else {
	    			this.popToast("failed", "Failed!", res.data.message);
	    			this.$store.commit('setUser', {questionnaire: false})
	    		}
	    		this.submitting = false
	    	}
	    	catch(e) {
	    		console.log(e);
	    		this.$store.commit('setUser', {questionnaire: false})
	    	}
	    },
		async redirectTracklite() {
			const response = await this.axios.get("/logout");
			localStorage.removeItem("vuex");
			localStorage.removeItem("_secure__ls__metadata");
			this.$store.commit('setUser', {});
			window.location="https://tracklite.in";
		},
	    async SignUpFormSubmit() {
	    	this.showQuestions = true

	    	try {
	    		this.submitting = true
	    		const res = await this.axios.post('/registerQuestionnairePref', {'preference': this.preference})

	    		if(res) {
	    			if (res.data.message.includes('undefined')) {
	    				this.popToast("failed", "Failed!", "Session Expired!!! Please Try Logging in again");
	    				this.$store.commit('setUser', {})
			            localStorage.removeItem("vuex");
			            localStorage.removeItem("_secure__ls__metadata")
			            setTimeout(() => {
							window.location="https://slv.shyplite.in"
	    				}, 1000)
			            return false;
	    			}

	    			this.$store.commit('setUser', {preference: this.preference})

	    			if (this.preference == 'Shipment Tracking') {
	    				this.countdown = 5
	    				setInterval(() => {
	    					if(this.countdown>0) {
	    						this.countdown--
	    					}
	    				}, 1000)
	    				setTimeout(async () => {
							this.redirectTracklite();
	    				}, 5000)
	    			} else if (this.preference == 'One-time Shipping') {
	    				this.$router.push('/kyc-info')
	    			}
		    		}else {
		    			this.popToast("failed", "Failed!", res.data.message);
		    		}
	    		this.submitting = false
	    	} catch(e) {
	    		console.log(e);
	    	}
	    }
	}
};
</script>

<style lang="scss">
.inputGroup {
	background-color: #fff;
	display: block;
	position: relative;
	border-radius: 5px;
	font-size: 16px;

	label {
		padding: 12px 30px;
		width: 100%;
		display: block;
		text-align: left;
		color: #3C454C;
		border-radius: 5px;
		cursor: pointer;
		position: relative;
		z-index: 2;
		transition: color 200ms ease-in;
		box-shadow: 0 0 2px #0006;
		overflow: hidden;

		&:before {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) scale3d(1, 1, 1);
			transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
			opacity: 0;
			z-index: -1;
		}
	}

	input:checked ~ label {
		box-shadow: 0 0 0 3px #64d778;

		&:before {
			transform: translate(-50%, -50%) scale3d(56, 56, 1);
			opacity: 1;
		}
		&:after {
			width: 32px;
			height: 32px;
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%2364d778' fill-rule='nonzero'/%3E%3C/svg%3E ");
			background-repeat: no-repeat;
			background-position: 5px 5px;
			border-radius: 50%;
			z-index: 2;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			transition: all 200ms ease-in;
		}
	}

	input {
		width: 32px;
		height: 32px;
		order: 1;
		z-index: 2;
		position: absolute;
		right: 30px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		visibility: hidden;
	}
}
.form {
	padding: 0 16px;
	margin: 50px auto;
	font-size: 18px;
	line-height: 36px;
	text-align: center;
}

.form-width {
	max-width : 64rem !important;
}
legend.col-form-label{font-weight: bold!important}
.mb300{margin-bottom:320px}
</style>
