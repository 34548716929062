<template>
  <div class="auth sign-up">
    <div class="auth-wrapper py-40">
      <div class="card mt-md-90">
        <div class="card__content pt-60 pb-52 px-56">
          <img
            src="@/assets/svg/logo_full.svg"
            alt=""
            class="d-block w-100 mw170 mx-auto mb-60"
          />

<!--           <div class="signupForm">
            <form @submit.prevent="verifyOtp(form.contact)">
              <b-form-group
                id="name-field"
                label="Name"
                label-for="name"
                class="mb-28"
              >
                <b-form-input
                  required
                  id="name"
                  v-model="form.name"
                  :disabled="otpSend"
                  oninvalid="this.setCustomValidity('Please Enter full name')"
                  oninput="this.setCustomValidity('')"
                  pattern=".* .*"
                  placeholder="Your complete name"
                  trim
                  maxlength="30"
                  @blur.native="disallowSpace(form.name,'name')"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="company-name-field"
                label="Company Name"
                label-for="companyname"
                class="mb-28"
              >
                <b-form-input
                  id="sellerCompanyName"
                  v-model="form.sellerCompanyName"
                  placeholder="Your company name"
                  trim
                  :disabled="otpSend"
                  @blur.native="disallowSpace(form.sellerCompanyName,'company')"
                  type="text"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="email-field"
                label="Email"
                label-for="email"
                class="mb-28"
              >
                <b-form-input
                  required  
                  id="email"
                  v-model="form.email"
                  :disabled="otpSend"
                  placeholder="Your email ID"
                  trim
                  type="text"
                  pattern="^[a-zA-Z0-9_%+-][a-zA-Z0-9_.%+-]+[a-zA-Z0-9_%+-]@[a-z0-9-]+\.[a-zA-Z]{0,9}[a-zA-Z.][a-zA-Z]{0,9}"
                  oninvalid="this.setCustomValidity('Please Enter a Valid Email ID')"
                  oninput="this.setCustomValidity('')"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="password-field"
                label="Password"
                label-for="password"
                class="mb-28"
              >
                <b-form-input
                  required
                  id="password"
                  type="password"
                  :disabled="otpSend"
                  v-model="form.password"
                  pattern=".{8,}"
                  placeholder="Your secure password (min. 8 characters)"
                  trim
                ></b-form-input>
              </b-form-group>

              <label for="contact">Contact</label>
              <b-input-group
                id="contact-field"
                label="Contact"
                label-for="contact"
                class="mb-28"
              >
                <b-form-input
                  required
                  id="contact"
                  v-model="form.contact"
                  placeholder="Your 10 digits contact number"
                  trim
                  :formatter="numbers"
                  type="text"
                  maxlength="10"
                  minlength="10"
                  :disabled="otpSend"
                  pattern="[6-9]{1}[0-9]{9}"
                  oninvalid="this.setCustomValidity('Enter a Valid Mobile Number')"
                  oninput="this.setCustomValidity('')"
                ></b-form-input>
                <div v-if="otpSend" class="input-group-append">
                    <div class="input-group-text z-99">
                      <b-button size="xxs" @click="otpSend=false; form.otp=''" variant="tertiary"> 
                        Edit
                      </b-button>
                    </div>
                </div>
              </b-input-group>
              
              <label v-if="otpSend" for="contact">One Time Password</label>
              <b-input-group
                id="otp-field"
                label="One Time Password"
                label-for="otp"
                class="mb-40"
                v-if="otpSend"
              >
                <b-form-input
                  id="otp"
                  v-model="form.otp"
                  placeholder="Enter OTP"
                  maxlength="6"
                  minlength="6"
                  :formatter="numbers"
                  type="text"
                  required
                ></b-form-input>
                <div v-if="countdown == 0" class="input-group-append">
                    <div class="input-group-text z-99">
                      <b-button size="xxs" :disabled="!resendOtp || sending" @click="ResendOtp(form.contact)" variant="tertiary"> 
                        <span>Resend <b-spinner v-if="sending" class="resend-spinner ml-10"></b-spinner></span> 
                      </b-button>
                    </div>
                </div>
              </b-input-group>

              <div class="d-flex" v-if="countdown > 0 && otpSend" style="position:absolute;bottom: 13rem; position: absolute; right: 4rem;">
                Resend OTP in  00:{{countdown >= 10 ? '' : '0'}}{{countdown}}
              </div>

              <input 
                id="hutk_cookie" 
                required 
                :value="form.hubspotcookie" 
                type="hidden"
              >

              <b-button
                variant="primary"
                size="lg"
                class="w-100 mb-20 mb-md-40"
                v-if="!otpSend"
                type="submit"
                :disabled="sending"
              >
                Submit <b-spinner v-if="sending" class="ml-10"></b-spinner>
              </b-button>

              <b-button
                variant="primary"
                size="lg"
                class="w-100 mb-20 mb-md-40"
                v-else
                @click="SignUpFormSubmit"
                :disabled="submitting || form.otp.length!=6"
              >
                Register <b-spinner v-if="submitting" class="ml-10"></b-spinner>
              </b-button>
            </form>
          </div> -->

          <div class="signupForm">
            <div class="text-center mb-40">
              <p class="fs18 fw500"> Sign up for Domestic shipping is currently unavailable</p>
              <p class="fs18 fw500"> <b-link class="fs18" target="_blank" href="https://app.shypmax.com/sign-up">Click here</b-link> to sign up for international shipping </p>
            </div>
          </div>

          <div class="d-flex flex-column align-items-center mb-20 mb-md-0">
            <span class="fs12 lh20 mb-16 text-gray-800">
              Already registered?
              <b-link href="/login">
                Log In
              </b-link>
            </span>
            <span class="fs12 lh20 text-gray-800">
              Contact us:
              <b-link href="mailto:info@shyplite.com">info@shyplite.com</b-link>
            </span>
          </div>

        </div>
      </div>
      <div class="bottom-bar pt-10 d-flex justify-content-between mx-40">
        <span class="fs12 lh20 text-gray-400 mb-16 mb-md-32">
          © 2021 Shyplite — All Rights Reserved.
        </span>
        <ul class="lsnone p-0 mb-16 mb-md-32">
          <li>
            <b-link target="_blank" href="https://shyplite.com/tou">Terms</b-link>
            <b-link class="ml-20" target="_blank" href="https://shyplite.com/privacy">Privacy</b-link>
            <b-link class="ml-20" target="_blank" href="https://shyplite.com/contact/">Contact Us</b-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import VueRouter from 'vue-router'
const { isNavigationFailure, NavigationFailureType } = VueRouter


export default {
  name: "SignIn",
  title: "Shyplite - Sign Up",
  data() {
    return {
      submitBtn : "Submit",
      otpSend : false,
      otpVerified : false,
      resendOtp : false,
      form:{
        name: "",
        email: "",
        password: "",
        contact: "",
        hubspotcookie: "",
        otp:""
      },
      submitting: false,
      timerStart: false,
      countdown:null,
      sending : false
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },

  created() {
    this.setMarketingKeys();
    this.form.hubspotcookie = (document.cookie.split(";").find(x => x.includes("hubspotutk=")) || "").trim().split("=").pop();
  },
  mounted() {
    document.querySelectorAll('a').forEach(i => {
        let href = i.getAttribute('href');
        if(window.location.search) {
            i.setAttribute('href', `${href}${window.location.search}`);
        }
    })
  },

  methods: {
    setMarketingKeys() {

      // referral key form insertion
      const at = (document.cookie.split(";").find(x => x.includes("at=") && !x.includes("_gat")) || "").trim().split("=").pop();
      if (at && at.length) {
          this.form['referralCode'] = at
      }

      let utm_source   = this.$route.query.hasOwnProperty('utm_source') ? this.$route.query.utm_source : false;
      let utm_medium   = this.$route.query.hasOwnProperty('utm_medium') ? this.$route.query.utm_medium : false;
      let utm_campaign = this.$route.query.hasOwnProperty('utm_campaign') ? this.$route.query.utm_campaign : false;
      let atCode       = this.$route.query.hasOwnProperty('at') ? this.$route.query.at : false;
      let keyword      = this.$route.query.hasOwnProperty('keyword') ? this.$route.query.keyword : false;
      
      if (utm_source) { localStorage.setItem("utm_source", utm_source); }
      if (utm_medium) { localStorage.setItem("utm_medium", utm_medium); }
      if (utm_campaign) { localStorage.setItem("utm_campaign", utm_campaign); }
      if (atCode) { localStorage.setItem("at", atCode); }
      if (keyword) { localStorage.setItem("keyword", keyword); }
      if (utm_source || utm_medium || utm_campaign || atCode || keyword) {                    
          let today         = new Date();
          let msAfter1Month = Math.floor(today.setMonth(today.getMonth() + 1));                
          localStorage.setItem('isUtmExpired', msAfter1Month);
      }

    },
    maxten(e){
      return String(e).substring(0, 10);
    },
    googleAnalytics() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'registration'
      });

      this.redirect_details();
    },
	redirect_details() {
		this.$router.push("/sign-up-detail").catch((e) => {
			if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
				Promise.reject(e)
			}
		})
	},
    disallowSpace(name,type) {
      if (type == 'name') {
        if (this.form.name && this.form.name.length) {
          this.form.name = name.split(' ').filter(i => i.trim().length).join(' ');
        } 
      } else {
        if (this.form.sellerCompanyName && this.form.sellerCompanyName.length) {
          this.form.sellerCompanyName = name.split(' ').filter(i => i.trim().length).join(' ');
        } 
      }
    },
    async getOtherInfo() {

      // GRAPH DATA...
      const now = Math.floor(Date.now()/1000)
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const chart = await this.axios.get(`/v3/dashboard/graphround?from=${firstDay.getTime()}&to=${lastDay.getTime()}`)
      this.$store.commit('setUser', {chart_timestamp: now + 3*3600})
      this.$store.commit('setCharts', chart.data)

      // NOTIFICATIONS DATA...
      const notice = await this.axios.get('/notification')
      if(notice.data.success) {
        this.$store.commit('setNotifications', notice.data.notifications)
      }


      // GET STATICS VARIABLES...
      const statics = await this.axios.get('/statics')
      this.$store.commit('setModes', statics.data.modes.filter(item => item.name != ''))
      this.$store.commit('setCarriers', statics.data.carriers.filter(item => item.name != ''))
      this.$store.commit('setChannels', statics.data.channels.filter(item => item.name != ''))
      this.$store.commit('setAllmodes', {'mps':statics.data.mps, 'b2b':statics.data.b2b, 'intl':statics.data.intl, 'domestic':statics.data.domestic})


      // GET WAREHOUSE LIST...
      let warehouses = await this.axios.get('/warehouse/list')
      this.$store.commit('setWarehouses', warehouses.data)

    },
    timer() {
      this.timerStart = setInterval(() => {
        if(this.countdown>0) {
          this.countdown--
        }
      }, 1000);
    },

    async ResendOtp(Contact) {
      this.sending = true;

      try {
        let form = Object.assign({}, this.form, {resend:true})
        const res = await this.axios.post('/register/sendcontactotp',form)
        if (res.data.success) {
          this.form.otp = ''
          this.popToast('booked','Success',res.data.message)

          this.countdown = 30

            if (this.timerStart) {
              clearInterval(this.timerStart);
            }
            this.timer();
            setTimeout(() => {
              this.resendOtp = true
            },30000)
        } else {
          // statement
          this.popToast("failed", "Failed!", res.data.message);
        }
      } catch(e) {
        // statements
        console.log(e);
      }
      this.sending = false;
    },  

    async verifyOtp(Contact) {
      this.sending = true

       try {
          let form = Object.assign({}, this.form)
          delete form.otp
          const res = await this.axios.post('/register/sendcontactotp', form)
          if (res.data.success) {
            this.otpSend = true
            this.submitBtn = "Register"
            this.countdown = 30

            this.popToast('booked','Success',res.data.message)

            if (this.timerStart) {
              clearInterval(this.timerStart);
            }

            this.timer();

            setTimeout(() => {
              this.resendOtp = true
            },30000)
          } else {
            this.otpSend = false
            this.popToast("failed", "Failed!", res.data.message);
          }
        } catch(e) {
          // statements
          console.log(e);
        }
        this.sending = false
    },

    async SignUpFormSubmit(event) {
        
        if (this.form.name && this.form.name.length) {
          this.form.name = this.form.name.split(' ').filter(i => i.trim().length).join(' ');
        }

        //setting necessary marketing keys just before submit
        let msNow = Math.floor(Date.now());                
        if(localStorage.getItem('isUtmExpired')) {
            if(localStorage.getItem('isUtmExpired') > msNow) {
                let utm_source   = localStorage.getItem("utm_source");
                let utm_medium   = localStorage.getItem("utm_medium");
                let utm_campaign = localStorage.getItem("utm_campaign");
                let at           = localStorage.getItem("at");
                let keyword      = localStorage.getItem("keyword");

                if (utm_source) { this.form['utmSource'] = utm_source; }
                if (utm_medium) { this.form['utmMedium'] = utm_medium;  }
                if (utm_campaign) { this.form['utmCampaign'] = utm_campaign;  }
                if (at) { this.form['referralCode'] = at;  }
                if (keyword) { this.form['keyword'] = keyword;  }
            } else {
                localStorage.clear();                        
            }
        }

        try {
          event.preventDefault()
          this.submitting = true
          const response = await this.axios.post("/register",this.form)
          
          if (response.data.success) {
              this.state = true;
              const user = response.data.user
              user.firstLogin = true;
              user.registrationCompleted = false;
              user.sellerStatus = 0;
              user.avatarImage = require("@/assets/img/user.png")
              this.$store.dispatch('login', user)
              if(this.user.profileStatus == 2) {
                this.user.registrationCompleted = true
                this.$store.commit('setRegistrationCompleted', true)
              }
              this.googleAnalytics();
            } else {
                this.popToast("failed", "Failed!", response.data.message.message);
            }
            this.submitting = false
          }
          catch(err) {
            console.log(err);
            this.submitting = false
            this.state = false;
            this.popToast("failed", "Failed!", "Form Submission Failed!!! Please Try again Later");
          }
        
      
    }
  }
};
</script>

<style scoped lang="scss">
  .bottom-bar {
    z-index: 0 !important;
  }
  .editImage {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }
  .input-group-text {
    background-color: transparent
  }
  /deep/ .resend-spinner{
    margin-right : unset !important;
    width: 1rem !important;
    height : 1rem !important;
  }
</style>
