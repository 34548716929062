var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth sign-up" }, [
    _c("div", {}, [
      _c(
        "div",
        {
          staticClass: "m-auto",
          staticStyle: { "min-height": "calc(100vh - 65px)" }
        },
        [
          _c(
            "div",
            { staticClass: "card__content pt-60 pb-52 px-56" },
            [
              _c("img", {
                staticClass: "d-block w-100 mw170 mx-auto mb-60",
                attrs: { src: require("@/assets/svg/logo_full.svg"), alt: "" }
              }),
              !_vm.user.preference
                ? _c(
                    "div",
                    { staticClass: "signupForm" },
                    [
                      _c(
                        "b-form",
                        {
                          staticClass: "form mw644",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.SignUpFormSubmit($event)
                            }
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-gray-800 fs18",
                              attrs: {
                                label: "What describes your requirement?"
                              }
                            },
                            [
                              _c("div", { staticClass: "inputGroup" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.preference,
                                      expression: "preference"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "option1",
                                    value: "One-time Shipping"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.preference,
                                      "One-time Shipping"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.preference = "One-time Shipping"
                                    }
                                  }
                                }),
                                _c("label", { attrs: { for: "option1" } }, [
                                  _vm._v(
                                    "I want to ship a Personal package (Non-Commercial)"
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "inputGroup" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.preference,
                                      expression: "preference"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "option2",
                                    value: "Regular Shipping"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.preference,
                                      "Regular Shipping"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.preference = "Regular Shipping"
                                    }
                                  }
                                }),
                                _c("label", { attrs: { for: "option2" } }, [
                                  _vm._v(
                                    "I have a business which requires Regular Shipping services (Commercial)"
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "inputGroup" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.preference,
                                      expression: "preference"
                                    }
                                  ],
                                  attrs: {
                                    type: "radio",
                                    id: "option3",
                                    value: "Shipment Tracking"
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      _vm.preference,
                                      "Shipment Tracking"
                                    )
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.preference = "Shipment Tracking"
                                    }
                                  }
                                }),
                                _c("label", { attrs: { for: "option3" } }, [
                                  _vm._v(
                                    "I want to check the Tracking status of my order"
                                  )
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "w-100 mb-20 mb-md-40",
                              attrs: {
                                variant: "primary",
                                size: "lg",
                                disabled: !_vm.preference || _vm.submitting,
                                type: "submit"
                              }
                            },
                            [
                              _vm._v(" Next "),
                              _vm.submitting
                                ? _c("b-spinner", { staticClass: "ml-10" })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "slide-left" } },
                [
                  _vm.user.preference == "Regular Shipping"
                    ? _c(
                        "b-form",
                        {
                          staticClass: "form form-width",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submitLastRegular($event)
                            }
                          }
                        },
                        [
                          _c("h4", { staticClass: "mb-40" }, [
                            _vm._v("Tell us about your business")
                          ]),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-gray-800 mb-30 fs18",
                              attrs: {
                                label: "What is your monthly order volume?"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.monthlyShipment, function(item) {
                                  return _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c("div", { staticClass: "inputGroup" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.monthlyShipment,
                                              expression: "form.monthlyShipment"
                                            }
                                          ],
                                          attrs: { type: "radio", id: item },
                                          domProps: {
                                            value: item,
                                            checked: _vm._q(
                                              _vm.form.monthlyShipment,
                                              item
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.form,
                                                "monthlyShipment",
                                                item
                                              )
                                            }
                                          }
                                        }),
                                        _c("label", { attrs: { for: item } }, [
                                          _vm._v(_vm._s(item))
                                        ])
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-gray-800 mb-30 fs18",
                              attrs: {
                                label:
                                  "Please select all of your sales channels."
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.channels, function(channel) {
                                  return _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c("div", { staticClass: "inputGroup" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.salesChannel,
                                              expression: "form.salesChannel"
                                            }
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: channel.name
                                          },
                                          domProps: {
                                            value: channel.name,
                                            checked: Array.isArray(
                                              _vm.form.salesChannel
                                            )
                                              ? _vm._i(
                                                  _vm.form.salesChannel,
                                                  channel.name
                                                ) > -1
                                              : _vm.form.salesChannel
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.form.salesChannel,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = channel.name,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.form,
                                                      "salesChannel",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.form,
                                                      "salesChannel",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.form,
                                                  "salesChannel",
                                                  $$c
                                                )
                                              }
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: channel.name } },
                                          [_vm._v(_vm._s(channel.name))]
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-gray-800 mb-30 fs18",
                              attrs: { label: "Do you have GST number?" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.isGST, function(item) {
                                  return _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c("div", { staticClass: "inputGroup" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.isGST,
                                              expression: "form.isGST"
                                            }
                                          ],
                                          attrs: {
                                            type: "radio",
                                            id: "gst_" + item
                                          },
                                          domProps: {
                                            value: item,
                                            checked: _vm._q(
                                              _vm.form.isGST,
                                              item
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.form,
                                                "isGST",
                                                item
                                              )
                                            }
                                          }
                                        }),
                                        _c(
                                          "label",
                                          { attrs: { for: "gst_" + item } },
                                          [_vm._v(_vm._s(item))]
                                        )
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "text-gray-800 mb-30 fs18",
                              attrs: { label: "Do you ship internationally?" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.shipInternational, function(item) {
                                  return _c(
                                    "div",
                                    { staticClass: "col-md-4" },
                                    [
                                      _c("div", { staticClass: "inputGroup" }, [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.form.shipInternational,
                                              expression:
                                                "form.shipInternational"
                                            }
                                          ],
                                          attrs: { type: "radio", id: item },
                                          domProps: {
                                            value: item,
                                            checked: _vm._q(
                                              _vm.form.shipInternational,
                                              item
                                            )
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.$set(
                                                _vm.form,
                                                "shipInternational",
                                                item
                                              )
                                            }
                                          }
                                        }),
                                        _c("label", { attrs: { for: item } }, [
                                          _vm._v(_vm._s(item))
                                        ])
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mb-20 mb-md-40",
                              attrs: {
                                variant: "primary",
                                size: "lg",
                                disabled: _vm.submitting,
                                type: "submit"
                              }
                            },
                            [
                              _vm._v(" Submit "),
                              _vm.submitting
                                ? _c("b-spinner", { staticClass: "ml-10" })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("transition", { attrs: { name: "slide-left" } }, [
                _vm.user.preference == "Shipment Tracking"
                  ? _c("div", { staticClass: "mb300 text-center" }, [
                      _c("h4", [
                        _vm._v("Redirecting you to order tracking page... ")
                      ]),
                      _c("h4", { staticClass: "mt-10" }, [
                        _vm._v(_vm._s(_vm.countdown))
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", {
                staticClass:
                  "d-flex flex-column align-items-center mb-20 mb-md-0"
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "bottom-bar pt-10 d-flex justify-content-between mx-40"
        },
        [
          _c(
            "span",
            { staticClass: "fs12 lh20 text-gray-400 mb-16 mb-md-32" },
            [_vm._v(" © 2021 Shyplite — All Rights Reserved. ")]
          ),
          _c("ul", { staticClass: "lsnone p-0 mb-16 mb-md-32" }, [
            _c(
              "li",
              [
                _c(
                  "b-link",
                  {
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/tou"
                    }
                  },
                  [_vm._v("Terms")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: {
                      target: "_blank",
                      href: "https://shyplite.com/privacy"
                    }
                  },
                  [_vm._v("Privacy")]
                ),
                _c(
                  "b-link",
                  {
                    staticClass: "ml-20",
                    attrs: { target: "_blank", href: "/support" }
                  },
                  [_vm._v("Contact Us")]
                )
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }